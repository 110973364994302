import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { message } from 'antd';

import client from '../client';
import { TUseVerifyEmail, VerifyEmailVariables } from '../../types/api/auth';
import { ISuccesMessage } from '../../types/global';

const url = '/api/auth/email/confirm';

const useVerifyEmail: TUseVerifyEmail = () => {
  const mutation = useMutation<AxiosResponse<ISuccesMessage>, AxiosError, VerifyEmailVariables, unknown>({
    mutationFn: (token) => {
      return client.post(url, token);
    },
    onError: (error) => {
      void message.error(error as unknown as string, 2);
    },
  });

  return mutation;
};

export default useVerifyEmail;
