import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Image, Typography, Space, Badge, Button, Modal, Form } from 'antd';
import dayjs from 'dayjs';
import { useQueryClient } from '@tanstack/react-query';

import { AUTH_KEYS, COLORS, STATUS, dateFormat, PATHS } from '../../constants';
import AsnButton from '../Button';
import { ReactComponent as EditSvg } from '../../assets/icons/editBlue.svg';
import { ReactComponent as DeleteSvg } from '../../assets/icons/deleteRed.svg';
import { IOtherStatement } from '../../types/components';
import { IUserDetails } from '../../types/auth';
import { useAuth } from '../../hooks/useAuth';
import RejectionReasonContent from '../Admin/Requests/RejectionReasonContent';
import useBlockAnnouncement from '../../api/admin/announcements/useBlockAnnoncement';
import FinishModal from '../FinishModal';
import { useDeleteStatementById } from '../../api';
import ActionModal from '../ActionModal';
import SuccessModal from '../SuccessModal';
import { ReactComponent as BackSvg } from '../../assets/icons/Back.svg';

const { Title, Paragraph } = Typography;
const { GREEN, TEXT_COLOR, AZURE, RED, RED_LIGHT, CORAL, GRAY } = COLORS;

const width = window.innerWidth;

const OtherStatementView: React.FC<IOtherStatement> = ({ other, isPrivate, modal }) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [openReject, setOpenReject] = useState<string>('');
  const [disabledButton, setDisabledButton] = useState(false);
  const [openFinishModal, setOpenFinishMOdal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const localStorageUser = JSON.parse(localStorage.getItem(AUTH_KEYS.USER)) as IUserDetails;
  const role = localStorageUser?.role;
  const { user } = useAuth();

  const { mutate: blockAnnouncement } = useBlockAnnouncement({
    onSuccess: () => {
      setDisabledButton(false);
      setOpenReject('');
      void queryClient.invalidateQueries(['/api/statements/:id', other.id]);
    },
  });

  const onBlockOther = (): void => {
    setOpenReject(other.id);
  };

  const onFinish = (value: { rejectionText: string }): void => {
    setDisabledButton(true);
    blockAnnouncement({
      id: openReject,
      ...value,
    });
  };

  const onClosRejectModal = (): void => {
    setOpenReject('');
    form.resetFields();
  };

  const { mutate: deleteOther } = useDeleteStatementById();

  const navigate = useNavigate();

  const onDelete = (): void => {
    deleteOther(other.id, {
      onSuccess: () => {
        setOpenSuccessModal(true);
      },
    });
  };

  const closeSuccessModal = (): void => {
    setOpenSuccessModal(false);
    navigate(PATHS.COMPANY_ANNOUNCMENTS);
  };

  const onBackClick = (): void => {
    if (role === 'SUPER_ADMIN' || role === 'ADMIN') {
      navigate(PATHS.ANNOUNCEMENTS);
    } else if (role === undefined) {
      navigate(-1);
    } else {
      navigate(PATHS.COMPANY_ANNOUNCMENTS);
    }
  };

  const handleNavigateCompany = (): void => {
    if (user?.id && user?.role !== 'COMPANY') {
      navigate(`${PATHS.COMPANY_VIEW_PUBLIC}/?id=${other?.company?.id}`);
    }
    if (!user?.id) {
      navigate(`${PATHS.COMPANY_VIEW_PUBLIC}/?id=${other?.company?.id}`);
    }
  };

  return (
    <>
      <Row style={{ paddingTop: 35, marginBottom: 48 }}>
        <Col span={22} pull={1} push={1} xxl={{ span: 18, pull: 3, push: 3 }}>
          {!modal && (
            <div style={{ cursor: 'pointer', marginBottom: 16 }} onClick={onBackClick}>
              <BackSvg />
            </div>
          )}
          <Row style={{ marginBottom: 16 }}>
            <Col
              xxl={{ span: 16 }}
              xl={{ span: 16 }}
              lg={{ span: 16 }}
              md={{ span: 15 }}
              xs={{ span: 24 }}
              sm={{ span: 24 }}
            >
              <Space
                direction={width <= 667 ? 'vertical' : 'horizontal'}
                style={{ alignItems: width <= 667 ? 'center' : 'flex-start', marginBottom: 48, width: '100%' }}
              >
                <Image
                  preview={false}
                  src={other?.company?.photo}
                  style={{ borderRadius: 6, objectFit: 'cover' }}
                  height={'160px'}
                  width={width <= 667 ? '80vw' : '160px'}
                />
                <Space direction='vertical' size={12}>
                  <Title
                    level={3}
                    style={{ marginBottom: 0 }}
                    ellipsis={{
                      rows: 2,
                      expandable: true,
                      symbol: <span style={{ fontSize: 16 }}>Տեսնել ավելին</span>,
                    }}
                  >
                    {other?.otherStatement?.title}
                    {!role ? null : (
                      <Badge
                        text={
                          other?.status === 'BLOCKED'
                            ? 'Արգելափակված'
                            : other?.status === 'DONE'
                            ? 'Ավարտված'
                            : other?.status === 'INACTIVE'
                            ? 'Ընթացիկ'
                            : 'Նոր'
                        }
                        color={
                          other?.status === 'BLOCKED'
                            ? `${CORAL}`
                            : other?.status === 'DONE'
                            ? `${GRAY}`
                            : other?.status === 'INACTIVE'
                            ? `${AZURE}`
                            : `${GREEN}`
                        }
                        style={{ alignItems: 'center', color: `${TEXT_COLOR}`, fontWeight: 400, marginLeft: 16 }}
                      />
                    )}
                  </Title>
                  <Paragraph
                    style={{ color: `${AZURE}`, marginBottom: '0.5em', cursor: 'pointer' }}
                    ellipsis={{
                      rows: 2,
                      expandable: true,
                    }}
                    onClick={(): void => handleNavigateCompany()}
                  >
                    {other?.company?.name}
                  </Paragraph>
                  {isPrivate && (
                    <>
                      <Space direction='horizontal' wrap>
                        {other?.status === 'ACTIVE' && (
                          <AsnButton
                            icon={<EditSvg />}
                            onClick={(): void => navigate(PATHS.OTHER_EDIT.replace(':id', other?.id))}
                          >
                            Խմբագրել
                          </AsnButton>
                        )}
                        {(other?.status === 'ACTIVE' || other?.status === 'INACTIVE') && (
                          <Button type='primary' onClick={(): void => setOpenFinishMOdal(true)}>
                            Ավարտել
                          </Button>
                        )}
                        {other?.status === 'ACTIVE' && (
                          <AsnButton
                            icon={<DeleteSvg />}
                            style={{ borderColor: `${RED}`, color: `${RED}` }}
                            color={RED_LIGHT}
                            onClick={(): void => setOpenDeleteModal(true)}
                          >
                            Ջնջել
                          </AsnButton>
                        )}
                      </Space>
                    </>
                  )}
                  {role === 'SUPER_ADMIN' || role === 'ADMIN' ? (
                    <Space direction='horizontal' align='center'>
                      {!(user?.permission === 'VIEW' && user.role === 'ADMIN') && (
                        <>
                          {other.status === STATUS.ACTIVE ? (
                            <AsnButton type='primary' onClick={onBlockOther}>
                              Արգելափակել
                            </AsnButton>
                          ) : null}
                        </>
                      )}
                      <span
                        style={{
                          color: TEXT_COLOR,
                        }}
                      >
                        {`Ստեղծված՝ ${dayjs(other?.otherStatement?.createdAt ?? '')
                          .utc()
                          .format(dateFormat)}`}
                      </span>
                    </Space>
                  ) : null}
                  {!role ? (
                    <Button
                      type='primary'
                      style={{ width: width <= 667 ? '100%' : 'auto' }}
                      href={`mailto:${other?.otherStatement?.registrationLink}`}
                    >
                      Դիմել հիմա
                    </Button>
                  ) : null}
                </Space>
              </Space>
            </Col>
            <Col
              xxl={{ span: 8 }}
              xl={{ span: 8 }}
              lg={{ span: 8 }}
              md={{ span: 8 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Space
                direction='vertical'
                style={{ background: 'rgba(31, 130, 255, 0.04)', padding: 16, width: '100%' }}
                size={12}
              >
                <Space>
                  <Paragraph style={{ fontWeight: 600, marginBottom: 0 }}>Գտնվելու վայրը (Մարզ)՝</Paragraph>
                  <Paragraph style={{ marginBottom: 0 }}>{other?.otherStatement?.region?.title}</Paragraph>
                </Space>
                <Space>
                  <Paragraph style={{ fontWeight: 600, marginBottom: 0 }}>Հասցե՝</Paragraph>
                  <Paragraph style={{ marginBottom: 0 }}>{other?.otherStatement?.location}</Paragraph>
                </Space>
                <Space>
                  <Paragraph style={{ fontWeight: 600, marginBottom: 0 }}>Հեռախոս՝</Paragraph>
                  <Paragraph style={{ marginBottom: 0 }}>{other?.otherStatement?.phone}</Paragraph>
                </Space>
                <div
                  style={{
                    borderRadius: '2px',
                    background:
                      'linear-gradient(90deg, rgba(31, 130, 255, 0.20) 0%, #1F82FF 51.2%, rgba(31, 130, 255, 0.20) 100%)',
                    height: '2px',
                  }}
                />
                <Space>
                  <Paragraph style={{ fontWeight: 600, marginBottom: 0 }}>Դիմելու վերջնաժամկետ՝</Paragraph>
                  <Paragraph style={{ marginBottom: 0 }}>
                    {dayjs(other?.otherStatement?.applicationDeadline)
                      .utc()
                      .format(dateFormat)}
                  </Paragraph>
                </Space>
                {other?.otherStatement?.startDate && (
                  <Space>
                    <Paragraph style={{ fontWeight: 600, marginBottom: 0 }}>Անցկացման օր՝</Paragraph>
                    <Paragraph style={{ marginBottom: 0 }}>
                      {dayjs(other?.otherStatement?.startDate)
                        .utc()
                        .format(dateFormat)}
                    </Paragraph>
                  </Space>
                )}
              </Space>
            </Col>
          </Row>
          <Row>
            <Col xxl={{ span: 16 }} xl={{ span: 16 }} lg={{ span: 16 }} md={{ span: 16 }} sm={{ span: 24 }}>
              {other?.otherStatement?.photo && !modal ? (
                <Image
                  preview={false}
                  src={other?.otherStatement?.photo}
                  style={{ objectFit: 'cover' }}
                  height={'370px'}
                  width={'100%'}
                />
              ) : null}
              <Title level={5} style={{ marginBottom: 24, marginTop: 48 }}>
                Ծրագիր
              </Title>
              <div
                className='list'
                dangerouslySetInnerHTML={{ __html: other?.otherStatement?.program || '' }}
                style={{ fontSize: 16, color: `${TEXT_COLOR}`, wordBreak: 'break-word' }}
              />
              {other?.otherStatement?.whatWeOffer && (
                <>
                  <Title level={5} style={{ paddingTop: 48, marginBottom: 24 }}>
                    Ի՞նչ ենք մենք առաջարկում (Ընկերության մասին)
                  </Title>
                  <div dangerouslySetInnerHTML={{ __html: other?.otherStatement?.whatWeOffer || '' }} />
                </>
              )}
            </Col>
          </Row>
        </Col>
        {!role && width <= 667 ? (
          <div style={{ padding: '4%' }}>
            <Button type='primary' style={{ width: '100%' }} href={`mailto:${other?.otherStatement?.registrationLink}`}>
              Դիմել հիմա
            </Button>
          </div>
        ) : null}
      </Row>
      <Modal centered open={openReject !== ''} onCancel={onClosRejectModal} footer={false} width={'40vw'}>
        <Form
          name='form_items'
          onFinish={onFinish}
          form={form}
          autoComplete='off'
          layout='vertical'
          style={{
            width: '100%',
            margin: '2rem 0 3rem',
          }}
        >
          <RejectionReasonContent
            rejectFn={onClosRejectModal}
            disabledButton={disabledButton}
            title='Համոզված եք, որ ցանկանում եք արգելափակել հայտարարությունը'
          />
        </Form>
      </Modal>
      {openFinishModal && (
        <FinishModal open={openFinishModal} setOpen={setOpenFinishMOdal} id={other?.id} type={'OTHER'} />
      )}
      {openDeleteModal && (
        <Modal centered open={openDeleteModal} onCancel={(): void => setOpenDeleteModal(false)} footer={false}>
          <ActionModal
            text={`Համոզվա՞ծ եք, որ ցանկանում եք հեռացնել Ձեր  հայտարարությունը`}
            resolveFn={onDelete}
            rejectFn={(): void => setOpenDeleteModal(false)}
          />
        </Modal>
      )}
      {openSuccessModal && (
        <SuccessModal
          openSuccessModal={openSuccessModal}
          onCloseModal={(): void => closeSuccessModal()}
          content='Ձեր հայտարարությունը հաջողությամբ հեռացվել է'
        >
          <AsnButton style={{ marginTop: 20 }} onClick={(): void => navigate(PATHS.COMPANY_ANNOUNCMENTS)}>
            Վերադառնալ իմ էջ
          </AsnButton>
        </SuccessModal>
      )}
    </>
  );
};

export default OtherStatementView;
