import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { message } from 'antd';

import client from '../client';
import { TUseResetPassword, ResetPasswordVariables } from '../../types/api/auth';
import { ISuccesMessage } from '../../types/global';
import { PATHS } from '../../constants';

const url = '/api/auth/password/reset';

const useResetPassword: TUseResetPassword = () => {
  const navigate = useNavigate();
  const mutation = useMutation<AxiosResponse<ISuccesMessage>, AxiosError, ResetPasswordVariables, unknown>({
    mutationFn: (values) => {
      return client.post(url, values);
    },
    onSuccess: (data) => {
      void message.success(data?.data?.result, 2);
      navigate(PATHS.SIGNIN);
    },
    onError: (error) => {
      void message.error(error as unknown as string, 2);
    },
  });

  return mutation;
};

export default useResetPassword;
